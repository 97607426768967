.shops {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 85vh;

    &__cards {
        display: flex;
        justify-content: center;
        gap: 1em;
        flex-wrap: wrap;
    }

    &__card {
        box-shadow: var(--box-shadow);
        background-color: white;
        padding: (50px * 0.8) (70px * 0.8);
        text-align: center;
        max-width: (515px * 0.8);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 1em;
        flex-grow: 1;
    }

    &__title {
        margin: 0;
    }

    &__text {
        margin-bottom: auto;

        p {
            margin: 0;
        }
    }

    &__button-container {
        margin-top: (70px * 0.8);
    }
}
